var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mf-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            visible: _vm.show,
            title: _vm.title,
            width: "550px",
            "on-confirm": _vm.onConfirm,
            "on-cancel": _vm.onCancel,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
            close: _vm.clearFiles,
          },
        },
        "mf-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-upload",
        {
          ref: "uploader",
          staticClass: "flex column",
          class: { list: _vm.showList },
          attrs: {
            drag: "",
            action: "#",
            accept: ".xls, .xlsx",
            "auto-upload": false,
            multiple: false,
            "on-exceed": function () {
              return _vm.$message.error("只能选择一个文件")
            },
            "on-change": _vm.handleChange,
            "on-remove": _vm.handleChange,
          },
        },
        [
          _c(
            "div",
            { staticClass: "upload-text" },
            [
              _c("svg-icon", {
                staticClass: "icon",
                attrs: { "icon-class": "icon-import" },
              }),
              _c("span", { staticClass: "text" }, [
                _vm._v(_vm._s(_vm.showList ? "重新上传" : "添加文件")),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "el-upload__text" }, [
            _vm._v("将文件拖到此处，或点击上传"),
          ]),
        ]
      ),
      _vm.$slots.tip
        ? _c(
            "div",
            { staticClass: "el-upload__tip flex middle" },
            [_vm._t("tip")],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }