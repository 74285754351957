var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    _vm._g(
      _vm._b(
        {
          model: {
            value: _vm.used,
            callback: function ($$v) {
              _vm.used = $$v
            },
            expression: "used",
          },
        },
        "el-radio-group",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.list, function (item) {
      return _c(
        "el-radio-button",
        { key: item.value, attrs: { label: item.value } },
        [_vm._v(_vm._s(item.title || item.label))]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }