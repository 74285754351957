var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: {
            visible: _vm.show,
            title: _vm.title,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.show = $event
            },
          },
        },
        "el-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._t("default"),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.$slots.footer
            ? _vm._t("footer")
            : [
                _c(
                  "el-button",
                  {
                    staticClass: "black",
                    attrs: { type: "text" },
                    on: { click: _vm.handleCancel },
                  },
                  [_vm._v("取消")]
                ),
                _c("el-button", { on: { click: _vm.handleConfirm } }, [
                  _vm._v("确定"),
                ]),
              ],
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }