var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo }, staticStyle: { height: "100%" } },
    [
      _vm.showLogo
        ? _c("logo", {
            attrs: { collapse: _vm.isCollapse },
            on: { toggle: _vm.toggle },
          })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.dynamicRouters) + " "),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper" } },
        [
          _c(
            "el-menu",
            {
              ref: "menu",
              attrs: {
                "default-active": _vm.activeMenu,
                collapse: _vm.isCollapse,
                "background-color": "#FFFFFF",
                "text-color": "#7A7C88",
                "active-text-color": "#252631",
                "unique-opened": "",
                "collapse-transition": false,
                mode: "vertical",
              },
              on: { select: _vm.selectSidebar },
            },
            _vm._l(_vm.dynamicRouters, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }