var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table-view" },
    [
      _c("div", { staticClass: "filter-bar flex between middle" }, [
        _c("div", { staticClass: "filter" }, [_vm._t("filter")], 2),
        _c("div", { staticClass: "operation" }, [_vm._t("operation")], 2),
      ]),
      _vm._t("default"),
      _c("div", { staticClass: "page-bar flex between middle" }, [
        _c("div", { staticClass: "page-left" }, [_vm._t("page-left")], 2),
        _vm.showPage
          ? _c(
              "div",
              { staticClass: "page-right" },
              [
                _vm._t("page-right", [
                  _c(
                    "div",
                    { staticClass: "page" },
                    [
                      _c("span", { staticClass: "total" }, [
                        _vm._v("共 " + _vm._s(_vm.total) + " 条"),
                      ]),
                      _c(
                        "mf-pagination",
                        _vm._g(
                          {
                            attrs: {
                              "current-page": _vm.currentPage,
                              "page-size": _vm.pageSize,
                              total: _vm.total,
                            },
                            on: {
                              "update:currentPage": function ($event) {
                                _vm.currentPage = $event
                              },
                              "update:current-page": function ($event) {
                                _vm.currentPage = $event
                              },
                              "update:pageSize": function ($event) {
                                _vm.pageSize = $event
                              },
                              "update:page-size": function ($event) {
                                _vm.pageSize = $event
                              },
                              "current-change": function ($event) {
                                return _vm.$emit("change")
                              },
                              "size-change": function ($event) {
                                return _vm.$emit("change")
                              },
                            },
                          },
                          _vm.$listeners
                        )
                      ),
                    ],
                    1
                  ),
                ]),
              ],
              2
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }