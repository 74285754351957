var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        width: "450px",
        title: _vm.title,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.visible = $event
        },
      },
    },
    [
      _c("div", { staticStyle: { "line-height": "1.5" } }, [
        _vm._v(_vm._s(_vm.content)),
      ]),
      _c(
        "div",
        { staticClass: "footer text-right" },
        [
          _c(
            "el-button",
            {
              staticClass: "black",
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.visible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              staticStyle: { "border-radius": "12px" },
              attrs: { type: _vm.comfirmBtnType, size: "small" },
              on: { click: _vm.confirmHandler },
            },
            [_vm._v(_vm._s(_vm.confirmButtonText))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }