var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "table-page full-page",
      class: _vm.isHasChannel ? "table-page-channel" : "",
    },
    [
      _vm.$slots.search
        ? _c(
            "div",
            {
              ref: "searchContainer",
              staticClass: "search-container flex",
              style: {
                height: _vm.searchHeight + "px",
              },
            },
            [
              _vm._t("search"),
              _vm.toggleShown
                ? _c(
                    "el-button",
                    {
                      staticClass: "toggle-button",
                      attrs: { type: "text" },
                      on: { click: _vm.toggleSearchContainer },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.showMoreSearch ? "收起更多" : "展开更多")
                      ),
                      _c("i", {
                        class:
                          (_vm.showMoreSearch
                            ? "el-icon-arrow-up"
                            : "el-icon-arrow-down") + " el-icon--right",
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c("el-scrollbar", { attrs: { "wrap-class": "scrollbar-wrapper" } }, [
        _c("div", { staticClass: "table-container" }, [_vm._t("default")], 2),
      ]),
      _vm.$slots.footer && _vm.showFooter
        ? _c("div", { staticClass: "footer-container" }, [_vm._t("footer")], 2)
        : _vm._e(),
      _vm._t("dialog"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }