var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.progress,
              expression: "progress",
            },
          ],
          staticClass: "flex middle",
          staticStyle: { height: "40px" },
        },
        [
          _c("el-progress", {
            staticStyle: { width: "240px" },
            attrs: { percentage: _vm.progress, "show-text": false },
          }),
          _c("el-button", { staticStyle: { opacity: "0" } }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.value && !_vm.progress,
              expression: "value && !progress",
            },
          ],
          staticClass: "uploaded",
        },
        [
          _vm.shownName
            ? _vm._t("uploaded", [
                _c(
                  "svg",
                  {
                    attrs: {
                      width: "20",
                      height: "20",
                      viewBox: "0 0 20 20",
                      fill: "none",
                      xmlns: "http://www.w3.org/2000/svg",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "fill-rule": "evenodd",
                        "clip-rule": "evenodd",
                        d: "M9.86 3C6.07145 3 3 6.07145 3 9.86C3 13.6485 6.07145 16.72 9.86 16.72C13.6485 16.72 16.72 13.6485 16.72 9.86C16.72 6.07145 13.6485 3 9.86 3ZM7.57422 7.23289C7.57422 6.72982 7.93231 6.52036 8.37318 6.76321L13.1848 9.41894C13.6257 9.6627 13.6247 10.0578 13.1848 10.3011L8.37318 12.9569C7.93185 13.2002 7.57422 12.9866 7.57422 12.4872V7.23289Z",
                        fill: "#2C66FF",
                      },
                    }),
                  ]
                ),
                _c("span", { staticStyle: { margin: "0 20px 0 5px" } }, [
                  _vm._v(_vm._s(_vm.shownName)),
                ]),
              ])
            : _vm._e(),
          _vm._t(
            "operation",
            [
              _c(
                "el-button",
                {
                  staticClass: "no-padding",
                  attrs: { type: "text", icon: "el-icon-refresh" },
                  on: { click: _vm.modifyFile },
                },
                [_vm._v("修改")]
              ),
              _c("el-divider", { attrs: { direction: "vertical" } }),
              _c(
                "el-button",
                {
                  staticClass: "no-padding black",
                  attrs: { type: "text", icon: "el-icon-close" },
                  on: { click: _vm.removeFile },
                },
                [_vm._v("删除")]
              ),
            ],
            { modify: _vm.modifyFile }
          ),
        ],
        2
      ),
      _c(
        "mf-uploader",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.value && !_vm.progress,
              expression: "!value && !progress",
            },
          ],
          ref: "fileUploader",
          attrs: {
            type: "product",
            accept: _vm.accept,
            "size-limit": _vm.sizeLimit,
            "before-upload": _vm.beforeUpload,
          },
          on: { success: _vm.uploadSuccess, error: _vm.uploadError },
        },
        [
          _vm._t("default", [
            _c(
              "mf-operation-button",
              [
                _c("svg-icon", { attrs: { "icon-class": "icon-import" } }),
                _vm._v(" 上传视频 "),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _vm.tip
        ? _c("div", { staticClass: "tip" }, [_vm._v(_vm._s(_vm.tip))])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }