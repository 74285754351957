var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "裁切图片",
        visible: _vm.showClipper,
        "append-to-body": "",
        "close-on-click-modal": false,
        "show-close": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showClipper = $event
        },
      },
    },
    [
      _c("clipper-basic", {
        ref: "clipper",
        attrs: { ratio: _vm.ratio, src: _vm.src, "cross-origin": "anonymous" },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("放弃裁切"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmEvent } },
            [_vm._v("确定裁切")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }