var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar-block flex", class: { inline: _vm.inline } },
    [
      _vm.showAvatar
        ? _c("el-avatar", {
            attrs: {
              shape: _vm.shape,
              size: _vm.size,
              src: _vm.avatar,
              fit: "cover",
            },
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "flex column center",
          style: {
            minHeight: _vm.size + "px",
            marginLeft: (_vm.showAvatar ? "10" : "0") + "px",
            overflow: "hidden",
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex row between" },
            [
              _vm.showTooltip &&
              (_vm.name && _vm.name.length) > _vm.tooltipLength
                ? _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: _vm.name,
                        placement: "top",
                      },
                    },
                    [
                      _c("span", { staticClass: "name ellipsis" }, [
                        _vm._v(" " + _vm._s(_vm.name) + " "),
                      ]),
                    ]
                  )
                : _c("span", { staticClass: "name ellipsis" }, [
                    _vm._v(" " + _vm._s(_vm.name) + " "),
                  ]),
              _vm.userTag
                ? _c("div", { staticClass: "user-tag" }, [
                    _vm._v(_vm._s(_vm.userTag)),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c(
            "span",
            { staticClass: "desc ellipsis", style: { fontSize: _vm.descSize } },
            [_vm._v(_vm._s(_vm.desc))]
          ),
          _vm.subdesc
            ? _c(
                "span",
                {
                  staticClass: "desc ellipsis",
                  style: { fontSize: _vm.descSize },
                },
                [_vm._v(_vm._s(_vm.subdesc))]
              )
            : _vm._e(),
        ]
      ),
      _vm.close
        ? _c(
            "div",
            {
              staticClass: "close-product-box",
              on: { click: _vm.closeProduct },
            },
            [
              _c("img", {
                staticClass: "product-close",
                attrs: { src: require("@/assets/close-product.png"), alt: "" },
              }),
              _c("img", {
                staticClass: "product-close-red",
                attrs: {
                  src: require("@/assets/close-product-red.png"),
                  alt: "",
                },
              }),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }