var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-upload",
    {
      ref: "uploader",
      attrs: {
        multiple: _vm.multiple,
        accept: _vm.accept,
        action: _vm.action,
        headers: _vm.headers,
        data: _vm.data,
        "show-file-list": false,
        "before-upload": _vm.handleBeforeUpload,
        "on-success": _vm.handleSuccess,
        "on-error": _vm.handleError,
        "http-request": _vm.handleHttpRequest,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }