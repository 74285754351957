var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-page edit-page" },
    [
      _c(
        "div",
        { class: { full: _vm.fullPage, bottom: _vm.$slots.footer } },
        [_vm._t("default")],
        2
      ),
      _vm.$slots.footer
        ? _c(
            "div",
            { staticClass: "footer flex right middle" },
            [_vm._t("footer")],
            2
          )
        : _vm._e(),
      _vm._t("dialog"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }